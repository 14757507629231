import styles from "../TopBar/TopBar.module.css";
import { setCookie, HIDE_TOP_BAR_COOKIE } from '../../utils/cookies';

const handleButtonClick = () => {
  setCookie(HIDE_TOP_BAR_COOKIE, 'true', 30);
};

const TopBarButton = ({ actionValue, buttonMessage }) => {

  if (actionValue === "other") {
    return (
      <a href="/country-language" className={`btn__green_outline ${styles.topbar__btn}`}>{buttonMessage}</a>
    );
  }
  else {
    return (
      <a href={actionValue.toLocaleLowerCase()} onClick={handleButtonClick} className={`btn__green_outline  ${styles.topbar__btn}`}>{buttonMessage}</a>
    );
  }
};
export default TopBarButton;
