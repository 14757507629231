import React, { useState, useEffect } from "react";
import { getCountryFlagData } from "../../api/getData";

const CountryFlag = ({ imageID }) => {
  const [loading, setLoading] = useState(true);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    getCountryFlagData(imageID, setImgUrl, setLoading);
  }, [imageID]);
  return (
    <>
      {loading ? null : (
        <>
            <img
              src={imgUrl}
              alt="flag"
            />
        </>
      )}
    </>
  )
}

export default CountryFlag;

