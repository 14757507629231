/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import styles from "../Testimonials/Testimonials.module.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { getReviewList, getReviewSummary, getReviewRating } from "../../api/getData";
import DraggableGrid from "./DraggableGrid";
import Loader from "../Loader/Loader";

const Testimonials = () => {

    const [ReviewData, setReviewData] = useState(null);
    // const [Page, setPage] = useState("1");//当前页
    const [pageNumber, setPageNumber] = useState("");//总页数
    const [Size, setSize] = useState("50");//每页多少条数据
    //const [Rating, setRating] = useState("");//显示评分多少的数据
    const [RatingSorting, setRatingSorting] = useState("-1");
    const [ReviewDate, setReviewDate] = useState("-1");
    const [loading, setLoading] = useState(true);
    const [ReviewSummary, setReviewSummary] = useState(null);
    //const [isDescription, setIsDescription] = useState(true);
    const [isStarsActive, setIsStarsActive] = useState(false);
    const [isTypeActive, setIsTypeActive] = useState(false);
    const [ReviewRating, setReviewRating] = useState(null);

    const params = new URLSearchParams(window.location.search);
    const Page = params.get('Page') || 1;
    const Rating = params.get('Rating') || "";
    const Location = params.get('Location') || "";
    const isDescription = params.get('isDescription') || "true";
    const startDropdown = useRef();
    const TypeDropdown = useRef();

    useEffect(() => {
        getReviewList(setReviewData, Page, Size, Rating, RatingSorting, ReviewDate, setPageNumber, setLoading, Location, isDescription);
    }, []);
    useEffect(() => {
        getReviewSummary(setReviewSummary);
        getReviewRating(setReviewRating);
    }, []);
    useEffect(() => {
        function handleClickOutside(event) {
            // 检查点击事件发生的位置是否在下拉框内
            if (!startDropdown.current || !startDropdown.current.contains(event.target)) {
                setIsStarsActive(false);
            }
            if (!TypeDropdown.current || !TypeDropdown.current.contains(event.target)) {
                setIsTypeActive(false);
            }
        }

        // 给整个文档添加点击事件监听器
        document.addEventListener('click', handleClickOutside);

        // 组件销毁时移除事件监听器
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };

    }, []);

    const RedirectWithParameter = (value, type) => {
        const params = new URLSearchParams(window.location.search);
        if (type === "Page") {
            params.set('Page', value);
            if (Rating !== "") {
                params.set('Rating', Rating);
            }
            if (Location !== "") {
                params.set('Location', Location);
            }
        } else if (type === "Rating") {
            params.set('Page', "1");
            params.set('Rating', value);
        } else if (type === "Location") {
            params.set('Page', "1");
            params.set('Location', value);
        }
        else if (type === "isDescription") {
            params.set('Page', "1");
            params.set('isDescription', value);
        }
        // 构建新的 URL
        const newUrl = `${window.location.pathname}?${params.toString()}`;

        // 跳转
        window.location.href = newUrl;
    };

    const handlePageChange = (newPage) => {
        RedirectWithParameter(newPage, "Rating");
    };
    const handleOptionChange = (e) => {
        if (e.target.value) {
            //setIsDescription(false);
            RedirectWithParameter(false, "isDescription");
        } else {
            //setIsDescription(true);
            RedirectWithParameter(true, "isDescription");
        }
    };

    const handleStarsClick = () => {
        setIsStarsActive(prev => !prev);
    }

    const handlePageNumberChange = (Number) => {
        RedirectWithParameter(Number, "Page");
        //etPage(Number);
    };
    const getPaging = () => {
        const content = [];
        const notSelected = `${styles['page-numbers']} ${styles['page-bottom']}`;
        const selected = `${styles['page-bottom']}`;

        if (parseInt(Page) !== 1) {
            content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(Page - 1)}>Previous</bottom>);
        }
        if (parseInt(Page) > 4) {
            content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(1)}>1</bottom>);
            content.push(<span className={selected}>...</span>);
        }
        if (pageNumber < 6) {
            for (let i = 1; i <= pageNumber; i++) {

                if (Page.toString() === i.toString()) {
                    content.push(<bottom className={selected}>{i}</bottom>);
                } else {
                    content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(i)}>{i}</bottom>);
                }
            }
        } else {

            if (parseInt(Page) < 3) {
                for (let i = 1; i <= 5; i++) {

                    if (Page.toString() === i.toString()) {
                        content.push(<bottom className={selected}>{i}</bottom>);
                    } else {
                        content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(i)}>{i}</bottom>);
                    }
                }
            } else {
                let a = parseInt(Page) - 2;
                let b = parseInt(Page) + 2;
                if ((pageNumber - Page) < 4) {
                    a = pageNumber - 5;
                    b = pageNumber;
                }
                for (let i = a; i <= b; i++) {

                    if (Page.toString() === i.toString()) {
                        content.push(<bottom className={selected}>{i}</bottom>);
                    } else {
                        content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(i)}>{i}</bottom>);
                    }
                }
            }

        }
        if (parseInt(pageNumber) - parseInt(Page) > 3) {
            content.push(<span className={selected}>...</span>);
            content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(pageNumber)}>{pageNumber}</bottom>);
        }
        if (Page.toString() !== pageNumber.toString()) {
            content.push(<bottom className={notSelected} onClick={() => handlePageNumberChange(parseInt(Page) + 1)}>Next</bottom>);
        }

        return content;

    };
    function renderStars(Summary, Average) {
        let starHTML = "";
        let proportion = 2.5 + Math.floor(Average) * 15 + (Average - Math.floor(Average)) * 10
        if (Math.floor(Average) === 5) {
          proportion = 72.5;
        }
        const DisplayWidth = (proportion).toFixed(2);

        starHTML += `<div class="star-content-box ${styles['starts-wrapper-box-div']}">`;
        starHTML += `<div class="star-emt-box ${styles['star-content-display']}">`;
        for (let i = 0; i < 5; i++) {
          starHTML += '<i class="bi bi-star"></i>';
        }
        starHTML += '</div>';
        // starHTML += '<div class="stat-fill-box" style="width: '+DisplayWidth+'px;">';
        starHTML += `<div class="stat-fill-box  ${styles['star-content-display']}"> `;
        for (let i = 0; i < 5; i++) {
            starHTML += '<i class="bi bi-star-fill"></i>';
        }
        starHTML += '</div>';
        starHTML += '</div>';
    
        starHTML += '<span>' + Average + '</span> &nbsp; (' + Summary + ' Reviews)';

        return <div >
            <div className={`start-sticky-total ${styles['starts-wrapper-item']}`} dangerouslySetInnerHTML={{ __html: starHTML }}></div>
        </div>;
    }
    return (

        <><>
            <div className={styles['Testimonials-top-div']}> </div>
            <div class="common-content">

                <div class="temp-white-block" >
                    <div class="top-content-box">
                        <div class="ab-title">Reviews from our clients</div>
                    </div>
                    <div className={`${styles['content-txt']} ${styles['top-content-box']}`}>
                        Up to date and meticulously gathered. Feedback is crucial for us and we take great pride in our multiple-awarded customer service. Below you can see what our clients really think about working with Packimpex.
                    </div>

                </div>
                <div className={styles['Testimonials-top-div']} > </div>

                <div class="content-body padding-clear-block">
                    <div className={`row match-height ${styles['row-div']}`}>

                        <div class="col-xl-6 col-lg-12 padding-clear-block">

                            <div data-select2-id="16">
                                <div class="card-body" data-select2-id="15">
                                    <div className={styles['filter-box']}>
                                        <div className={`${styles['items-style']} ${styles['custom-radio']}`} onChange={handleOptionChange}>
                                            <input type="radio" checked={isDescription === "false"} value="All" name='test' id="radio1" />
                                            <label for="radio1">All</label>
                                        </div>
                                        <div className={`${styles['items-style']} ${styles['custom-radio']}`} onChange={handleOptionChange}>
                                            <input type="radio" checked={isDescription === "true"} value="" name='test' id="radio2" />
                                            <label for="radio2">Only show reviews with comments</label>
                                        </div>

                                        <div className={`${styles['custom-dropdown']} ${styles['starts-wrapper-toggle']} `} ref={startDropdown}>
                                            <div className={`${styles['starts-wrapper']} start-icon`}>
                                                <div>Stars:</div>
                                                <div className={`${styles['slected-content']}`}>
                                                    <div className={`${styles['selected-stars']}`}>
                                                        <div className={`${styles['nav-item-tab']}`}>
                                                            <div className={`${styles['nav-link']}`} id="about-tab" onClick={handleStarsClick}>

                                                                <div className={`${styles['show-start']} ${styles['bi-interval-about']}`}>

                                                                    {Rating === "" ? (<span>All</span>) : (
                                                                        <>
                                                                            <div className={`${styles['left-bi-star']}`}>
                                                                                <i className="bi bi-star-fill"></i>
                                                                                <i className={`bi ${Rating >= 2 ? 'bi-star-fill' : 'bi-star'}`}></i>
                                                                                <i className={`bi ${Rating >= 3 ? 'bi-star-fill' : 'bi-star'}`}></i>
                                                                                <i className={`bi ${Rating >= 4 ? 'bi-star-fill' : 'bi-star'}`}></i>
                                                                                <i className={`bi ${Rating >= 5 ? 'bi-star-fill' : 'bi-star'}`}></i>
                                                                            </div>
                                                                            <div className={`${styles['stars-num']}`}>
                                                                                {ReviewRating ? '(' + (
                                                                                    Rating >= 5 ? ReviewRating.five :
                                                                                        Rating >= 4 ? ReviewRating.four :
                                                                                            Rating >= 3 ? ReviewRating.three :
                                                                                                Rating >= 2 ? ReviewRating.two : ReviewRating.one) + ')' : null
                                                                                }</div>
                                                                        </>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`${styles['arrow-box']} ${isStarsActive ? styles.active : ''}`} onClick={handleStarsClick}>
                                                        <img className={`${styles['allow-box-img']}`} alt="Arrow" src="/packimpex/images/NL_icons/Angle_down.png" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className={`${styles['select-tab']} ${styles['dropdown-content']} ${styles['block']}`} id="starsDropdown" style={{ display: isStarsActive ? 'block' : 'none' }} >
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`} >
                                                    <a className={`${styles['nav-link']} ${Rating === "" ? styles.starsfocus : ''}`} id="about-tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("")}>

                                                        <div className={`${styles['drop-stars']}`}>
                                                            <span>All</span>

                                                        </div>

                                                    </a>
                                                </div>
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`}>
                                                    <a className={`${styles['nav-link']} ${styles['select-stars']} ${Rating === "5" ? styles.starsfocus : ''}`} id="about-tab" data-toggle="tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("5")}>

                                                        <div className={`${styles['drop-stars']}`}>
                                                            <div className={`${styles['left-bi-star']}`}>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                            </div>
                                                            <div className={`${styles['stars-num']}`}>{ReviewRating ? `(${ReviewRating.five})` : ''}</div>
                                                        </div>

                                                    </a>
                                                </div>
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`}>
                                                    <a className={`${styles['nav-link']} ${styles['select-stars']} ${Rating === "4" ? styles.starsfocus : ''}`} id="about-tab" data-toggle="tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("4")}>

                                                        <div className={`${styles['drop-stars']}`}>
                                                            <div className={`${styles['left-bi-star']}`}>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star"></i>
                                                            </div>
                                                            <div className={`${styles['stars-num']}`}>{ReviewRating ? `(${ReviewRating.four})` : ''}</div>
                                                        </div>

                                                    </a>
                                                </div>
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`}>
                                                    <a className={`${styles['nav-link']} ${styles['select-stars']} ${Rating === "3" ? styles.starsfocus : ''}`} id="about-tab" data-toggle="tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("3")}>

                                                        <div className={`${styles['drop-stars']}`}>
                                                            <div className={`${styles['left-bi-star']}`}>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                            </div>
                                                            <div className={`${styles['stars-num']}`}>{ReviewRating ? `(${ReviewRating.three})` : ''}</div>
                                                        </div>

                                                    </a>
                                                </div>
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`}>
                                                    <a className={`${styles['nav-link']} ${styles['select-stars']} ${Rating === "2" ? styles.starsfocus : ''}`} id="about-tab" data-toggle="tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("2")}>

                                                        <div className={`${styles['drop-stars']}`}>
                                                            <div className={`${styles['left-bi-star']}`}>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                            </div>
                                                            <div className={`${styles['stars-num']}`}>{ReviewRating ? `(${ReviewRating.two})` : ''}</div>
                                                        </div>

                                                    </a>
                                                </div>
                                                <div className={`${styles['nav-item-tab']} ${styles['stars']}`} >
                                                    <a className={`${styles['nav-link']} ${styles['select-stars']} ${Rating === "1" ? styles.starsfocus : ''}`} id="about-tab" data-toggle="tab" aria-controls="about" role="tab" aria-selected="false" onClick={() => handlePageChange("1")}>
                                                        <div className={`${styles['drop-stars']}`}>
                                                            <div className={`${styles['left-bi-star']}`}>
                                                                <i class="bi bi-star-fill"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                                <i class="bi bi-star"></i>
                                                            </div>
                                                            <div className={`${styles['stars-num']}`}>{ReviewRating ? `(${ReviewRating.one})` : ''}</div>
                                                        </div>
                                                    </a>
                                                </div>








                                            </div>

                                        </div>

                                        <div className={`${styles['select-wrapper-box']}`}>

                                            <div class="start-sticky-new">
                                                <label>
                                                    <div>
                                                        {ReviewSummary ? (
                                                            renderStars(ReviewSummary.Summary, ReviewSummary.Average)) : null}

                                                    </div>
                                                </label>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div>
                                <div className={`card-body ${styles['container']}`}>


                                    <div class="col-lg-12">
                                        <div class="tab-content">
                                            {loading ? <div className={styles['Loader-div']}><Loader /></div> :
                                                <DraggableGrid items={ReviewData} />}


                                            <br />

                                        </div>

                                    </div>

                                </div>
                                {/* 分页开始 */}
                                <div className={styles['easy_t_pagination']}>
                                    {pageNumber > 1 ?
                                        (
                                            getPaging()
                                        )
                                        : null
                                    }

                                </div>
                                {/* 分页结束 */}
                            </div>
                        </div>
                    </div>

                </div>
            </div></><div class="h80"></div></>

    )
};
export default Testimonials;