export const DRUPAL_API_ENDPOINT = 'https://cms.packimpex.com';
export const FETCH_ALL_URLS = '/jsonapi/node/packimpex_web_page/';
export const FETCH_SPECIFIC_COMPONENT = '/jsonapi/paragraph/';
export const FETCH_MEDIA_FOR_THE_COMPONENT = '/jsonapi/file/file/';
export const FETCH_MEDIA_DATA = '/jsonapi/media/image/';
export const IP_API = 'https://ipapi.co/json';

export const REQUEST_URL = 'https://review-api.siia.group';
export const COMPANYCODE = 'Packimpex';
export const ENVIRONMENT = 'production';

