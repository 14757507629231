import React, { useState, useEffect } from "react";
import { getHtmlEditorData } from "../../api/getData";
import { COMPONENT_HTML_EDITOR } from "../../constants/componentTypes";
import InnerHTML from "dangerously-set-html-content";

function HtmlEditor({ componentID, resourceVersionId, currentCountryCode }) {
  const [loading, setLoading] = useState(true);
  const [editor, setEditor] = useState("");

  useEffect(() => {
    getHtmlEditorData(
      setEditor,
      COMPONENT_HTML_EDITOR,
      componentID,
      setLoading,
      resourceVersionId,
      currentCountryCode
    );
  }, [componentID]);

  return <>{!loading && <InnerHTML html={editor} />}</>;
}

export default HtmlEditor;
