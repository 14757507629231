import React, { useEffect, useState } from 'react';
import { getC16VideoCard } from "../../api/getData";
import { CONTAINER_VIDEO_CARD } from "../../constants/componentTypes";
import C16_VIDEO from "../Video/C16_Video";

export default function C16_Video_Card({ componentID, currentCountryCode, resourceVersionId }) {
    const [VideoCardData, setPageVideoCardData] = useState(null);

    useEffect(() => {
        getC16VideoCard(CONTAINER_VIDEO_CARD, componentID, setPageVideoCardData, resourceVersionId, currentCountryCode)
    }, [componentID]);

    return (
        <>
            <div class="common-content">
                <div class="content-body">
                    <div class="row">
                        {VideoCardData &&
                            VideoCardData.map(
                                (data) => {
                                    return (
                                        <C16_VIDEO componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                                    );
                                }
                            )}
                    </div>
                </div>
            </div>
            <div className="h80"></div>
        </>
    )
}