import React from 'react'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
export default function MenuItems({ setSearchActive, menuItems }) {
  return (
    <div className="header-container custom-header-css">
      <MenuDesktop items={menuItems} setSearchActive={setSearchActive}></MenuDesktop>
      <MenuMobile items={menuItems}></MenuMobile>
    </div>
  )
}