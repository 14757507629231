import React, { useEffect } from 'react'
import MenuContentMobile from './MenuContentMobile'
import ComponentDecider from "../ComponentDecider" 
import { useSelector } from "react-redux";

export default function MenuMobile(props) {
  const globalLinks = useSelector(state => state.menu.globalLinks);
  const topBarActive = useSelector(state => state.menu.topBarActive);
  
  function loadJqueryFunction() {
    console.log('loaded')
    window.localStorage.setItem('mobile_script_loaded', true)
    window.jQuery('.header-menu-mb').click(function () {
      var a = window.jQuery('.mb-menu');
      var b = window.jQuery('.mb-close');
      if (window.jQuery('.header-menu-mb').hasClass('show-nav')) {
        a.removeClass('hide');
        b.removeClass('show');
      } else {
        a.addClass('hide');
        b.addClass('show');
      }
    });

    const ham = document.getElementById('ham');
    ham.addEventListener('click', function () {
      document.body.classList.toggle('nav-is-toggled');
      if (window.jQuery('.hamburger').hasClass('show-nav')) {
        window.jQuery('.hamburger').removeClass('show-nav');
      } else {
        window.jQuery('.hamburger').addClass('show-nav');
      };
    });
  }
  useEffect(() => {
    if (!window.mobileJqueryForMenu) {
      loadJqueryFunction()
      window.mobileJqueryForMenu = () => {

      }
    }
  }, [])
  return (
    <nav className={`nav-drill ${topBarActive ? 'active' : ''}`}>
      <ul className="header-menus">
      <li className="header-register"><a href={globalLinks?.attributes?.field_get_in_touch_button_link} className="btn mobile-js-register w-100"> {globalLinks?.attributes?.field_get_in_touch_button_text} </a></li>
        {
          props?.items?.map((item) => {
            return (
              <li className="" key={item.id}>
                <div className="menu-controller-text">
                  {/* <a href='javascript:;' className='text-color'>{item?.attributes?.field_mega_menu_title}</a> */}
                  <a href={item?.mega_menu_url} target={item?.mega_menu_open_type} className="text-color">{item?.title}</a>
                </div>
                <MenuContentMobile item={item}></MenuContentMobile>
                {/* <ComponentDecider items={[item?.relationships?.field_mega_?.data]} is_mobile={true}></ComponentDecider> */}
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}
