import React, { useEffect, useState } from 'react'
import axios from 'axios'
import APIS from '../../config/api'
import ComponentDecider from '../themeComponents/ComponentDecider';
import { FooterItem } from '../themeComponents';
const Footer = () => {
	const [items, setItems] = useState([])
	// let countAPICalls = 0
	const loadMenuItems = (whichHeader, loadParent = false) => {
		// countAPICalls++
		// if (countAPICalls > 5) return false
		axios.get(`${APIS.CUSTOM_FOOTER_MENU}?countryLang=${whichHeader}`).then((res) => {
			var footer_menu = Object.keys(res.data.footer_menu).map(function (k) { return res.data.footer_menu[k] });
			if (footer_menu.length) {
				const sortedItems = footer_menu.sort((a, b) => {
					let aValue = a?.attributes?.field_sort_number_footer
					let bValue = b?.attributes?.field_sort_number_footer
					if (!aValue) {
						aValue = footer_menu.length + 1
					}
					if (!bValue) {
						bValue = footer_menu.length + 1
					}
					return aValue - bValue
				})

				setItems(sortedItems)
			}
			// else {
			//   if (loadParent) {
			//     loadMenuItems('/', true)
			//   }
			// }
		})
	}
	useEffect(() => {
		const path = window.location.pathname.split('/')
		let whichHeader = '/'
		if (path.length >= 2) {
			whichHeader = path[1]
			loadMenuItems(whichHeader, true)
		} else {
			loadMenuItems(whichHeader)
		}
	}, [])
	const year = new Date().getFullYear();
	return (
		<footer>
			<div className="container block-temp flex-row d-flex align-items-start" id="footer-b">
				{
					items.map((item) => {
						return (
							<div className="col-lg-3 footer-item" key={item.id}>
								<p> {item?.field_footer_menu_main_title} <i className="bi bi-chevron-down mobile-show"></i></p>
								<ul>
									{
										item?.submenu.map((subItem) => {
											return <FooterItem key={subItem.menu_item_id} item={subItem}></FooterItem>
										})
									}
								</ul>
							</div>
						)
					})
				}
			</div>
		</footer>

	);
};

export default Footer;
