import React, { useEffect, useState } from 'react';
import { getSpacerData } from '../../api/getData';
import { COMPONENT_SPACE } from '../../constants/componentTypes';

const Spacer = ({ componentID, resourceVersionId, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [data, setPageData] = useState({});

  useEffect(() => {
    getSpacerData(setPageData, COMPONENT_SPACE, componentID, setLoading, resourceVersionId, currentCountryCode);
  }, []);
  return (
    <>
      {loading ? null : (
        <div className={data.field_class_name}></div>
      )}
    </>
  )
}

export default Spacer
