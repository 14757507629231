import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import styles from "../Video/Video.module.css";
import { getC17Video } from "../../api/getData";
import { COMPONENT_C17_VIDEO } from "../../constants/componentTypes";

export default function C17_Video({ componentID, currentCountryCode, resourceVersionId }) {
    const [C17Video, setC17Video] = useState(null);
    const [ImgUrl, setImgUrl] = useState(null);
    const [Autostart, setAutostart] = useState(0);
    useEffect(() => {
        getC17Video(COMPONENT_C17_VIDEO, componentID, setC17Video, setImgUrl, resourceVersionId, currentCountryCode)

    }, [componentID]);
    const handleClick = () => {
        setImgUrl(null);
        setAutostart(1);
    };
    return (
        <>
            <div class="common-content">
                <div className={`${styles['temp-white-block']} ${styles['cursor']}`} onClick={handleClick}>
                    <div className={`${styles['video-body']}`}>
                        <figure className={`${styles['content-media-C17video']}`} id="featured-media">
                            {ImgUrl ? (
                                <><div className={`${styles['video-icon-box']}`}>
                                    <img src="/packimpex/images/NL_icons/Play.svg" alt="" className="video-icon-svg" />
                                </div>
                                    <picture>
                                        <source media="(max-width: 767px)" srcset={ImgUrl.replace("sites/default/files", "sites/default/files/styles/large/public")} />
                                        <img src={ImgUrl} alt='' className={`${styles['img-responsive']}`} onError={(e) => {
                                            if (!e.target.dataset.error) {
                                                e.target.dataset.error = 'true';
                                                const source = e.target.parentElement.querySelector('source');
                                                source.srcset = ImgUrl;
                                            }
                                        }} />
                                    </picture></>

                            ) : (
                                C17Video && C17Video.field_youtube_id && (
                                    <VideoPlayer className={`${styles['C17video']}`}
                                        videoId={C17Video.field_youtube_id}
                                        width=""
                                        height=""
                                        autostart={Autostart}
                                    />
                                )
                            )}
                        </figure>
                    </div>
                </div>
            </div>
            <div className="h80"></div>
        </>
    )
}