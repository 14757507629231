import React , { useEffect, useState } from 'react';
import {
  SOCIAL_MEDIA
} from "../../constants/componentTypes";
import { getSocialMedia } from '../../api/getData';
import {
  DRUPAL_API_ENDPOINT
} from "../../constants/drupalApiEndPoints";
import Image from '../themeComponents/Image'
export default function WhichMedia(props) {
  const [loading, setLoading] = useState(true);
  const [data, setPageData] = useState(true);

  useEffect(() => {
    getSocialMedia(setPageData,SOCIAL_MEDIA, props.keyId,setLoading)
  }, [props.keyId])
  const imageId = () => {
    return  data?.relationships?.field_social_media_image?.data?.id
  }
  return (
    <>
      { loading ? <div></div> : <a target="_blank" href={data?.attributes?.field_title_3} className="footer_icon">
        <Image baseurl={DRUPAL_API_ENDPOINT} imageid={imageId()} type='file' width={150} height={150}></Image>
      </a> }
    </>
  )
}
