import React, { useEffect, useState } from 'react';
import styles from "../CardSlider/CardSlider.module.css";
import { getCardSliderData } from '../../api/getData';
import { CONTENT_COMPONENT_FOUR_CARD } from '../../constants/componentTypes';
import { replaceTextLanguage } from '../../utils/textUtils';

const CardSlider = ({ componentID, currentCountryCode, resourceVersionId }) => {
  const [loading, setLoading] = useState(true);
  const [fieldTitle, setFieldTitle] = useState(null);
  const [btnUrl, setBtnUrl] = useState(null);
  const [btnTitle, setBtnTitle] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [openType, setOpenType] = useState(null);
  const [TitlePosition, setTitlePosition] = useState(null);

  useEffect(() => {
    getCardSliderData(setFieldTitle, setBtnUrl, setBtnTitle, setImgUrl, CONTENT_COMPONENT_FOUR_CARD, componentID, setLoading, setOpenType, setTitlePosition, currentCountryCode, resourceVersionId);
  }, []);
  return (
    <>
      {loading ? null : (
        <div className={`img-fluid ${styles.cardSlider__image}`}>
          {imgUrl &&
            <picture>
              <source media="(max-width: 767px)" srcset={imgUrl.replace("sites/default/files", "sites/default/files/styles/large/public")}/>
              <img src={imgUrl} alt={replaceTextLanguage(currentCountryCode, btnUrl)} width="700" height="1000" onError={(e) => {
                if (!e.target.dataset.error) {
                  e.target.dataset.error = 'true';
                  const source = e.target.parentElement.querySelector('source');
                  source.srcset = imgUrl;
                }
              }} />
            </picture>
          }
          <div className={`${styles.cardSlider__top__area}`}>
            {btnUrl && btnTitle &&
              <button className={`btn__white `}>
                <a href={replaceTextLanguage(currentCountryCode, btnUrl)} target={openType}>{btnTitle}</a>
              </button>
            }

          </div>
          <div className={`${styles.cardSlider__contents} ${TitlePosition ? `title-position-${TitlePosition}` : ''}`}>
            <h4>
              {fieldTitle}
            </h4>
          </div>
        </div >
      )}
    </>
  )
}

export default CardSlider;
