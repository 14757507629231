import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import styles from "../Video/Video.module.css";
import { getC16Video } from "../../api/getData";
import { COMPONENT_C16_VIDEO } from "../../constants/componentTypes";

export default function C16_Video({ componentID, currentCountryCode, resourceVersionId }) {
    const [PageVideoCardData, setPageVideoCardData] = useState(null);
    const [ImgUrl, setImgUrl] = useState(null);
    const [BtnUrl, setBtnUrl] = useState(null);
    const [Autostart, setAutostart] = useState(0);
    useEffect(() => {
        getC16Video(COMPONENT_C16_VIDEO, componentID, setPageVideoCardData, setImgUrl, setBtnUrl, resourceVersionId, currentCountryCode)

    }, [componentID]);
    const handleClick = () => {
        if (BtnUrl) {
            navigateToUrl(BtnUrl, PageVideoCardData.field_url_open_type)
        } else {
            setImgUrl(null);
            setAutostart(1);
        }

    };
    const navigateToUrl = (url, type) => {
        switch (type) {
            case '_blank':
                window.open(url, '_blank');
                break;
            case '_new':
                window.open(url, '_blank');
                break;
            case '_parent':
                window.open(url, '_parent');
                break;
            case '_top':
                window.open(url, '_top');
                break;
            case '_self':
            default:
                window.location.href = url;
                break;
        }
    }
    return (
        <>
            <div className={`col-lg-6 col-md-6 col-xs-12 top-card-b ${styles['cursor']}`} onClick={handleClick}>
                <div className="card-block" >
                    <div className={`${styles['card-image']}`} >
                        <figure className={`content-media ${ImgUrl ? styles['content-media--img'] : styles['content-media--video']}`} id="featured-media" >
                            {ImgUrl ? (
                                <><div className={`${styles['video-icon-box']}`}>
                                    <img src="/packimpex/images/NL_icons/Play.svg" alt="" className="video-icon-svg" />
                                </div>
                                    <picture>
                                        <source media="(max-width: 767px)" srcset={ImgUrl.replace("sites/default/files", "sites/default/files/styles/large/public")} />
                                        <img src={ImgUrl} alt='' className={`${styles['img-responsive']}`} onError={(e) => {
                                            if (!e.target.dataset.error) {
                                                e.target.dataset.error = 'true';
                                                const source = e.target.parentElement.querySelector('source');
                                                source.srcset = ImgUrl;
                                            }
                                        }} />
                                    </picture>
                                    <div className={`card-title ${styles[`video-title-${PageVideoCardData.field_title_position}`]}`}>
                                        {PageVideoCardData.field_title}
                                    </div>
                                </>
                            ) : (
                                PageVideoCardData && PageVideoCardData.field_youtube_id && (
                                    <VideoPlayer
                                        videoId={PageVideoCardData.field_youtube_id}
                                        width=""
                                        height=""
                                        autostart={Autostart}
                                    />
                                )
                            )}
                        </figure>
                    </div>
                </div>
            </div>
        </>
    )
}