
import React from "react";
import styles from "../BannerWithTitleDescButton/TriCards.module.css";
import CardSlider from "../CardSlider/CardSlider";

const TriCards = ({ cardsData,currentCountryCode }) => {
  return (
    <>
        <div className={`${styles.tri__card__container}`}>
            <div className="container">
                <div className={`${styles.cardWithTitleImageDescButton__container}`}>
                    <div className={` ${styles.card__container}`}>
                        {cardsData.map((data, index) => {
                            return (
                                <CardSlider key={index} componentID={data.id} currentCountryCode={currentCountryCode}/>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};
export default TriCards;
