import React, { useState, useEffect } from "react";
import styles from "../ZigzagTriCard/ZigzagTriCard.module.css";
import { getZigzagTriCardSingleData } from "../../api/getData";
import { THREE_CARD_COMPONENT_WITH_TITLE } from '../../constants/componentTypes';
import { replaceTextLanguage } from '../../utils/textUtils';

const ZigzagTriCardSingle = ({ componentID, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [fieldTitle, setFieldTitle] = useState(null);
  const [fieldDescription, setFieldDescription] = useState(null);
  const [btnUrl, setBtnUrl] = useState(null);
  const [btnTitle, setBtnTitle] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [openType, setOpenType] = useState(null);
  const [TitlePosition, setTitlePosition] = useState(null);
  useEffect(() => {
    getZigzagTriCardSingleData(setFieldTitle, setFieldDescription, setBtnUrl, setBtnTitle, setImgUrl, THREE_CARD_COMPONENT_WITH_TITLE, componentID, setLoading, setOpenType, setTitlePosition, currentCountryCode);
  }, []);
  return (
    <>
      {loading ? null : (
        <>
          <div key={componentID} className={`${styles.card__image} img-fluid`}>
            <picture>
              <source media="(max-width: 767px)" srcset={imgUrl.replace("sites/default/files", "sites/default/files/styles/large/public")} />
              <img src={imgUrl} alt={replaceTextLanguage(currentCountryCode, btnUrl)} width="700" height="1000" onError={(e) => {
                if (!e.target.dataset.error) {
                  e.target.dataset.error = 'true';
                  const source = e.target.parentElement.querySelector('source');
                  source.srcset = imgUrl;
                }
              }} />
            </picture>
            {btnTitle &&
              <div className={`${styles.single__card__button}`}>
                <button className={`btn__white`}>
                  <a href={replaceTextLanguage(currentCountryCode, btnUrl)} target={openType}>{btnTitle}</a>
                </button>
              </div>
            }
            <div className={`${styles.singleCard__content} ${TitlePosition ? `title-position-${TitlePosition}` : ''}`}>
              <h4>{fieldTitle}</h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: fieldDescription,
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ZigzagTriCardSingle
