import {
    DRUPAL_API_ENDPOINT
  } from "../constants/drupalApiEndPoints";
const APIS = {
    pages : `${DRUPAL_API_ENDPOINT}/jsonapi/node/packimpex_web_page`,
    IMAGE: `${DRUPAL_API_ENDPOINT}/jsonapi/media/image/{id}/field_media_image`,
    FILE: `${DRUPAL_API_ENDPOINT}/jsonapi/file/file/{id}/`,
    MEGA_MENU: `${DRUPAL_API_ENDPOINT}/jsonapi/block_content/mega_menu/`,
    MEGA_MENU_COTAINER: `${DRUPAL_API_ENDPOINT}/jsonapi/paragraph/mega_menu_container/`,
    MEGA_MENU_ITEM: `${DRUPAL_API_ENDPOINT}/jsonapi/paragraph/mega_menu_item/`,
    FOOTER_MENU: `${DRUPAL_API_ENDPOINT}/jsonapi/block_content/footer_block/`,
    FOOTER_MENU_ITEM: `${DRUPAL_API_ENDPOINT}/jsonapi/paragraph/footer_menu_item/`,
    DYNAMIC_SCRIPT: `${DRUPAL_API_ENDPOINT}/jsonapi/block_content/dynamic_scripts/`,
    SOCIAL_MEDIA:`${DRUPAL_API_ENDPOINT}/jsonapi/block_content/social_media/`,
    CUSTOM_MEGA_MENU: `${DRUPAL_API_ENDPOINT}/custom_jsonapi/mega_menu`,
    CUSTOM_FOOTER_MENU: `${DRUPAL_API_ENDPOINT}/custom_jsonapi/footer_menu`,
    SEARCH: `${DRUPAL_API_ENDPOINT}/custom_jsonapi/search`
}
export default  APIS