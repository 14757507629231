import React from "react";
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";
import './plyr.css'; // 导入自定义样式表
const YouTubePlayer = ({ videoId, width, height, autostart, className }) => {
  const videoSource = {
    type: "video",
    sources: [
      {
        src: `https://www.youtube.com/watch?v=${videoId}`,
        provider: "youtube",
      },
    ],
  };
  const options = {
    controls: ["play-large", "play", "progress", "current-time", "mute", "volume", "fullscreen", "airplay"],
    youtube: { noCookie: true, rel: 0, showinfo: 0 },
    autoplay: autostart ? 1 : 0,
  };

  return (
    <div className={className}>
      <Plyr source={videoSource} options={options} />
    </div>
  );
};

export default YouTubePlayer;