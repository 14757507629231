export const HIDE_TOP_BAR_COOKIE = "hideTopBar";
export const COUNTRY_DATA_COOKIE = "countryData";
export const HIDE_TOP_BAR_LOAD_COUNT_COOKIE = "loadCount";

export const setCookie = (name, value, days = 0) => {
    if (days !== 0) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires};path=/`;
    } else {
        document.cookie = `${name}=${encodeURIComponent(value)};path=/`;
    }
}

export const getCookie = (name) => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key.trim() === name.trim()) {
            return decodeURIComponent(value);
        }
    }
    return null;
}

export const deleteCookie = (name) => {
    const expires = new Date(Date.now() - 1).toUTCString();
    document.cookie = `${name}=;expires=${expires};path=/`;
}

