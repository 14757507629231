import { useState, useEffect } from "react";
import { getCountryData } from "../../api/getData";
import styles from "../MultiCountryLan/MultiCountryLan.module.css";
import flag from "../../assets/belgium-round.svg";
import MultiCountry from "./MultiCountry";
import CountryFlag from "./CountryFlag";
import LanguagePage from "../../assets/image/language-page.jpg";
import GlobalBox from "../../assets/image/global-box.svg";
const MultiCountryLan = ({ setSelectedLanguage, setSelectedCountry }) => {
  const [loading, setLoading] = useState(true);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    getCountryData(setCountryData, setLoading);
    const metaElement = document.createElement('meta');
    metaElement.setAttribute('name', 'description');
    metaElement.setAttribute('content', 'Interested in a specific country? Go to its dedicated website for more details.');
    document.head.append(metaElement);

    return () => {
      // 组件卸载时执行清理操作
      document.head.removeChild(metaElement);
    };
  }, []);
  return (
    <>
      <div className="pxy-100">
        {loading ? null : (
          <div className="multicountry__area">
            <div className="container">
              <div className={styles.heading__area}>
                <h1>
                  Choose a<br />
                  region and language
                </h1>
                <div className={`row ${styles.row_left}`}>
                  {countryData &&
                    countryData.map((data, index) => (
                      <>
                        {data.attributes.name === "Global" ? (
                          ""
                        ) : (
                          <div key={index} className={`lan__card__area col-lg-4 col-md-6 ${styles.language_block_div}`} >
                            <div className={`${styles.language__block}`}>
                              <div
                                className={`${styles.multicountry__contents}`}
                              >
                                <div className={`${styles.lang__area}`}>
                                  <div className={`${styles.lang__title}`}>
                                    {data.relationships.field_country_flag
                                      ?.data ? (
                                      <CountryFlag imageID={data.relationships.field_country_flag.data.id} />
                                    ) : (
                                      <img src={flag} alt="flag" />
                                    )}
                                    <span>{data.attributes.name}</span>
                                  </div>
                                  <div className={`${styles.languages__area}`}>
                                    <ul>
                                      <MultiCountry
                                        countryLanguages={
                                          data.relationships
                                            .field_country_languages?.data
                                        }
                                      />
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}

                  <div className={`${styles.content_txt} ${styles.top_content_box} ${styles.language_global}`} >
                    <div className={`${styles.global_box}`}>
                      <div className={`${styles.left_language_text}`}>
                        <div className={`${styles.global_title}`}>
                          <img src={GlobalBox} className={`${styles.global_title_img}`} alt='' />
                          <span className={`${styles.global_title_box}`}>Global</span>
                        </div>
                        <div className={`${styles.text_content_wrap}`}>In addition to the above country language options, you can also go to the packimpex global site.</div>
                        <div className={`${styles.cont_txt}`}><a href="/" className="common-btn-border global-btn">CONTINUE →</a></div>
                      </div>
                      <div className={`${styles.right_images}`}>
                        <img src={LanguagePage} alt='' />
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MultiCountryLan;
