import React, { useState, useEffect } from "react";
import styles from "../C18_PointLists/C18_PointLists.module.css";
import { getC18PointListsData } from "../../api/getData";
import { COMPONENT_C18_POINT_LISTS_COMPONENT } from "../../constants/componentTypes";
import Point from './Point';

export default function C18_PointLists({ componentID, resourceVersionId, currentCountryCode }) {
  const [C18PointListsData, setC18PointListsData] = useState(null);

  useEffect(() => {
    getC18PointListsData(COMPONENT_C18_POINT_LISTS_COMPONENT, componentID, setC18PointListsData, resourceVersionId, currentCountryCode);
  }, [componentID, currentCountryCode, resourceVersionId]);

  return (
    <>
      {
        C18PointListsData && (
          <>
            <div className="container">
              <div className={`row`}>
                <div className={`col-md-12 ${styles.banner__container}`}>
                  <div className="col-lg-9 col-md-12 p-0">
                    <div className={` ${styles.banner__content}`}>
                        {
                          C18PointListsData.attributes.field_title_5 &&
                          <h2 style={{ textAlign: C18PointListsData.attributes.field_title_position }}>
                            {C18PointListsData.attributes.field_title_5}
                          </h2>
                        }
                        {C18PointListsData.relationships.field_point_lists &&
                          C18PointListsData.relationships.field_point_lists.data.map((data, index) => (
                            <div key={index} className="cont-item">

                              <Point componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />

                            </div>
                          ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};
