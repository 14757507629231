export const BANNER_WITH_TITLE_IMAGE = "banner_with_title_component";
export const CONTENT_COMPONENT_WITH_IMAGE_TITLE =
  "content_component_with_image_tit";
export const TWO_COLUMN_COMPONENT_YEAR_TITLE =
  "two_column_component_year_title_";
export const CONTENT_COMPONENT_WITH_TITLE = "content_component_with_title_and";
export const CONTENT_COMPONENT_WAVE = "c5_wave_component";
export const CONTENT_COMPONENT_FOUR_CARD = "four_card_component";
export const CARD_COMPONENT_WITH_TITLE_IMAGE =
  "card_component_with_title_image_";
export const THREE_CARD_COMPONENT_WITH_KICKER =
  "three_card_component_with_kicker";
export const THREE_CARD_COMPONENT_WITH_TITLE = "c11_three_card_comp_with_title";
export const COMPONENT_DIVISION_CONTAINER = "c12_division_container";
export const COMPONENT_SPACE = "c13_space";
export const COMPONENT_CARD_WITH_IMAGE_TITLE =
  "card_component_with_image_title_";
export const COMPONENT_HTML_EDITOR = "c14_html_editor";
export const COMPONENT_C15_H1 = "c15_h1_component";
export const SOCIAL_MEDIA = "social_media";
export const CONTAINER_VIDEO_CARD = "video_card_container";
export const COMPONENT_C17_VIDEO = "c17_video_component";
export const COMPONENT_C16_VIDEO = "c16_video_card_component";
export const COMPONENT_C18_POINT_LISTS_COMPONENT = "c18_point_lists_component";
