import React, { useEffect, useState } from "react";
import { getZigzagHexaCardsData } from "../../api/getData";
import { THREE_CARD_COMPONENT_WITH_KICKER } from "../../constants/componentTypes";
import { default as styles, default as stylesZigzagCards } from "../ZigzagHexaCards/ZigzagHexaCards.module.css";
import ZigzagCards from "./ZigzagCards";

const ZigzagHexaCards = ({ componentID, resourceVersionId, currentCountryCode }) => {
  const [loading, setLoading] = useState(true);
  const [headlineData, setHeadlineData] = useState(null);
  const [titleData, setTitleData] = useState(null);
  const [titlePosition, setTitlePosition] = useState(null);
  const [zigzagHexaCardData, setZigzagHexaCardData] = useState(null);
  const [svg, setSvg] = useState(null);
  const [kickerPosition, setKickerPosition] = useState(null);

  useEffect(() => {
    getZigzagHexaCardsData(setHeadlineData, setTitleData, setTitlePosition, setZigzagHexaCardData, setSvg, THREE_CARD_COMPONENT_WITH_KICKER, componentID, setLoading, setKickerPosition, resourceVersionId, currentCountryCode);
  }, []);



  return (
    <>
      {loading ? null : (
        <>
          <div className={`${styles.hexa__card__container}`}>
            <div className={`container ${styles.tricard__title__area}`}>
              {headlineData || titleData ? (
                <div className={`row align-items-right`}>
                  <div col-md-12>
                    <span className={`${kickerPosition ? `title-position-${kickerPosition}` : ''}`}>{headlineData}</span>
                    <h6 className={`${titlePosition ? `title-position-${titlePosition}` : ''}`}>{titleData}</h6>
                  </div>
                </div>
              ) : null}
              <div className={` ${stylesZigzagCards.cardWithTitleImageDescButton__container}`}>
                <div className={` ${stylesZigzagCards.card__container}`}>
                  {zigzagHexaCardData &&
                    zigzagHexaCardData.map(
                      (data) => {
                        return (
                          <div className={` ${styles.individual__tri__card}`}>
                            <ZigzagCards componentID={data.id} resourceVersionId={data.meta.target_revision_id} currentCountryCode={currentCountryCode} />
                          </div>
                        );
                      }
                    )}
                </div>
              </div>
            </div>
          </div>

          {svg !== null && (
            <>
              <div
                className={styles.svg__block__1__up}
                dangerouslySetInnerHTML={{
                  __html: svg,
                }}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
export default ZigzagHexaCards;
